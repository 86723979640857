<template lang="pug">
	v-menu(bottom left offset-x)
		template(#activator="{on, attrs}")
			v-btn(icon v-on="on" v-bind="attrs" absolute).activator
				v-icon(color="black") mdi-web
		v-list(flat)
			v-list-item-group(v-model="language" color="primary")
				v-list-item(v-for="lang in languages" :key="lang.value" :value="lang.value")
					v-list-item-title {{lang.text}}
</template>
<script lang="ts">
	import { Component, Vue } from "vue-property-decorator"
	import Localization from "@/managers/session/localization";

	@Component({ components: {} })
		export default class LanguageSelector extends Vue {
		private get languages(): ISelectOption[] {
			const options = Localization.languageNames
			return Object.keys(options).map(k => ({value: k, text: options[k]}))
		}

		private get language(): string {
			return Localization.current
		}
		private set language(value: string) {
			Localization.current = value
		}
	}

	export interface ISelectOption {
		readonly value: string
		readonly text: string
	}
</script>
<style lang="sass" scoped>
	.activator
		position: absolute
		top: 2px
		right: 10px
</style>

<template lang="pug">
	v-container(fill-height fluid).justify-center
		v-progress-circular(
			:size="size"
			color="mediumgrey"
			width="2"
			indeterminate
		)
</template>
<script lang="ts">
	import { Component, Prop, Vue } from "vue-property-decorator"

	@Component({ components: {} })
		export default class Loading extends Vue {
		@Prop({ default: 24 })
		size!: number
	}
</script>

<template lang="pug">
	v-app-bar.mb-4.mb-lg-16.flex-grow-0(
		:height="$vuetify.breakpoint.lgAndUp ? 150 : 123"
		color="secondary"
		flat
	)
		.d-flex.flex-grow-1.flex-column.align-center.pt-lg-3
			v-avatar(:size="$vuetify.breakpoint.lgAndUp ? 72 : 52").mb-3.mb-lg-2
				img(:src="logo")
			v-toolbar-title.text-body-1.font-weight-bold {{name}}
		LanguageSelector
</template>
<script lang="ts">
	import { Component, Prop, Vue } from "vue-property-decorator"
	import LanguageSelector from "@/components/LanguageSelector.vue";

	@Component({ components: {LanguageSelector} })
		export default class Header extends Vue {
		@Prop({required: true})
		public readonly name!: string
		@Prop({required: true})
		public readonly logo!: string
	}
</script>

<template lang="pug">
	v-container(fill-height).justify-center
		.primary--text.text-center.mb-15.px-15 {{$tk("errorNoToken")}}
</template>
<script lang="ts">
	import {Component, I18nViewMixin} from "@/mixins/combinations"

	@Component({ components: {} })
		export default class Error extends I18nViewMixin {

	}
</script>

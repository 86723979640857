/* eslint-disable @typescript-eslint/no-explicit-any */

import Vue, { DirectiveOptions, VNode } from "vue"
import type { DirectiveBinding } from "vue/types/options"
import VueI18n, { LocaleMessages } from "vue-i18n"
import numberFormats from "@/locales/numberFormats.yaml"
import Configuration from "@/managers/system/configuration"

const { contextId, messages: localeMessages} = loadLocaleMessages()
const i18n = initialize()
enableHotReload()

function createDirective(source: DirectiveOptions): DirectiveOptions {
	return {
		bind: (el: any, binding: DirectiveBinding, vNode: VNode, oldNode: any) => {
			updatePath(binding, vNode)
			source.bind!(el, binding, vNode, oldNode)
		},
		update: (el: any, binding: DirectiveBinding, vNode: VNode, oldNode: any) => {
			updatePath(binding, vNode)
			source.update!(el, binding, vNode, oldNode)
		},
		unbind: source.unbind
	}
}

function updatePath(binding: DirectiveBinding, node: VNode): void {
	const key = (node.context as any).i18nKey
	if (key === undefined)
		throw new Error("i18nKey property is missing on: " + node.context)

	if (typeof binding.value === "string")
		(binding as any).value = key + binding.value
	else
		binding.value.path = key + binding.value.path
}

function loadLocaleMessages(): { contextId: string, messages: LocaleMessages} {
	const localeContext = require.context("../locales", false, /\/[a-z]{2}\.yaml$/i, "sync")
	const messages: LocaleMessages = {}
	localeContext.keys().forEach(key => {
		const matched = key.match(/([A-Za-z]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1]
			messages[locale] = localeContext(key)
		}
	})

	return {contextId: localeContext.id as any as string, messages}
}

function getBrowserLanguageCode(): string | null {
	const language = (window.navigator as any).userLanguage || window.navigator.language

	if (language === undefined || language === null || language === "" || language.length < 2)
		return null
	return language.slice(0, 2)
}

function initialize(): VueI18n {
	Vue.use(VueI18n)
	const tDirective = Vue.directive("t")
	if (!tDirective)
		throw new Error("T directive is missing")

	Vue.directive("tk", createDirective(tDirective))

	const browserLanguage = getBrowserLanguageCode()
	let locale = Configuration.localization.default

	if (browserLanguage !== null && Object.hasOwn(localeMessages, browserLanguage))
		locale = browserLanguage

	return new VueI18n({
		locale,
		fallbackLocale: Configuration.localization.fallback,
		numberFormats,
		messages: localeMessages
	})
}

export function enableHotReload(): void {
	if (module.hot)
		module.hot.accept(contextId, () => {
			const {messages: newMessages} = loadLocaleMessages()

			Object.keys(newMessages)
				.filter(locale => localeMessages[locale] !== newMessages[locale])
				.forEach(locale => {
					localeMessages[locale] = newMessages[locale]
					i18n.setLocaleMessage(locale, localeMessages[locale])
				})
		})
}

export default i18n
